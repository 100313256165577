/*--------------------------------------------------------------
#0.5	footer
--------------------------------------------------------------*/
.site-footer {
	background-color: #1d1d1d;
	position: relative;

	ul {
		list-style: none;
	}

	p,
	li {
		color: #b6b6b6;
	}


	.upper-footer {
		padding: 90px 0;

		@include media-query(991px) {
			padding: 90px 0 20px;
		}

		@include media-query(767px) {
			padding: 80px 0 10px;
		}

		.col {
			@include media-query(991px) {
				min-height: 235px;
				margin-bottom: 70px;
			}

			@include media-query(767px) {
				min-height: auto;
				margin-bottom: 60px;
			}
		}
	}

	.widget-title {
		margin-bottom: 30px;

		@include media-query(767px) {
			margin-bottom: 20px;
		}
	}

	.widget-title h3 {
		font-size: 18px;
		font-size: calc-rem-value(18);
		font-weight: blod;
		color: $white;
		margin: 0;
		padding-bottom: 0.4em;
		text-transform: capitalize;
		position: relative;
	}

	/*** about-widget ***/
	.about-widget {
		.logo {
			max-width: 180px;
		}

		p {
			margin-bottom: 0.8em;
			line-height: 1.9em;
		}

		p:last-child {
			margin-bottom: 0;
		}
	}

	/*** link-widget ***/
	.link-widget {
		overflow: hidden;

		@include widther(1200px) {
			padding-left: 20px;
		}

		@include media-query(1199px) {
			padding-left: 20px;
		}

		@include media-query(991px) {
			padding-left: 0;
		}

		@include media-query( 767px ) {
	       max-width: 350px;
	    }

		ul {
			width: 50%;
			float: left;
		}

		ul li {
			position: relative;
		}

		ul a {
			color: #b6b6b6;
		}

		ul a:hover,
		ul li:hover:before {
			color: $theme-primary-color;
		}

		ul > li + li {
			margin-top: 15px;
		}
	}

	/*** contact-widget ***/
	.contact-widget {
		@include widther(1200px) {
			padding-left: 25px;
		}

		ul li {
			position: relative;
		}

		ul > li + li {
			margin-top: 15px;
		}
	}

	/*** newsletter-widget ***/
	.newsletter-widget {
		form {
			margin-top: 25px;
			position: relative;

			input {
				background-color: #2e2e2e;
				height: 50px;
				color: $white;
				padding: 6px 20px;
				border-radius: 0;
				border: none;
				box-shadow: none;
			}

			.submit {
				position: absolute;
				right: 20px;
				top: 55%;
				@include translatingY();

				button {
					background: transparent;
					border: 0;
					outline: 0;
					font-size: 20px;
					color: $theme-primary-color;
					margin-top: -2px;
					position: relative;
					right: -5px;
				}
			}
		}
	}


	/*** lower-footer ***/
	.lower-footer {
		text-align: center;
		position: relative;

		.row {
			padding: 20px 0;
			position: relative;

			.separator {
				background: rgba(255, 255, 255, 0.05);
				width: calc(100% - 30px);
				height: 1px;
				position: absolute;
				left: 15px;
				top: 0;
			}
		}

		.copyright {
			display: inline-block;
			float: left;
			font-size: 14px;
			font-size: calc-rem-value(14);
			margin: 0;

			@include media-query(991px) {
				float: none;
				display: block;
				margin: 0 0 20px;
			}
		}

		.social-icons {
			display: inline-block;
			float: right;
			overflow: hidden;

			@include media-query(991px) {
				float: none;
				display: block;
				text-align: center;
			}
		}

		.social-icons ul {
			overflow: hidden;
			list-style: none;

			@include media-query(991px) {
				display: inline-block;
			}

			li {
				float: left;
			}

			> li + li {
				margin-left: 15px;
			}

			a {
				font-size: 14px;
				font-size: calc-rem-value(14);
				color: #b6b6b6;
			}

			a:hover {
				color: $theme-primary-color;
			}
		}

	}
}