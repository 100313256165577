.section-title {
	text-align: center;
	margin-bottom: 50px;

	> span {
		font-size: 16px;
		font-size: calc-rem-value(16);
		color: $theme-primary-color;
		display: block;
		margin-top: -6px;
		text-transform: capitalize;
	}

	h3 {
		font-size: 26px;
		font-size: calc-rem-value(26);
		font-weight: normal;
		line-height: 1.3em;
		margin: 0.45em 0 0;

		@include media-query(991px) {
			font-size: 22px;
			font-size: calc-rem-value(22);
		}

		@include media-query(767px) {
			font-size: 20px;
			font-size: calc-rem-value(20);
		}
	}
}


.section-title-s2 {
	margin-bottom: 40px;

	@include media-query(991px) {
		margin-bottom: 25px;
	}

	> span {
		font-size: 16px;
		font-size: calc-rem-value(16);
		color: $theme-primary-color;
		display: block;
		margin-top: -6px;
		text-transform: capitalize;
		position: relative;

		&:before {
			content: "";
			background: $theme-primary-color;
			width: 45px;
			height: 1px;
			position: absolute;
			left: -65px;
			top: 12px;
		}
	}

	h2 {
		font-size: 38px;
		font-size: calc-rem-value(38);
		font-weight: bold;
		line-height: 1.2em;
		margin: 0.2em 0 0;
		text-transform: capitalize;

		@include media-query(991px) {
			font-size: 32px;
			font-size: calc-rem-value(32);
		}

		@include media-query(767px) {
			font-size: 28px;
			font-size: calc-rem-value(28);
		}
	}
}


.section-title-s3 {
	margin-bottom: 60px;
	text-align: center;

	@include media-query(991px) {
		margin-bottom: 50px;
	}

	> span {
		font-size: 16px;
		font-size: calc-rem-value(16);
		color: $theme-primary-color;
		display: block;
		margin-top: -6px;
		text-transform: capitalize;
		position: relative;
	}

	h2 {
		font-size: 38px;
		font-size: calc-rem-value(38);
		font-weight: bold;
		line-height: 1.2em;
		margin: 0.2em 0 0.5em;
		text-transform: capitalize;

		@include media-query(991px) {
			font-size: 32px;
			font-size: calc-rem-value(32);
		}

		@include media-query(767px) {
			font-size: 28px;
			font-size: calc-rem-value(28);
		}
	}
}

.section-title-s4 {
	@extend .section-title-s2;

	h2 {
		color: $white;
	}
}