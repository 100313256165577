	/*
  	Flaticon icon font: Flaticon
  	Creation date: 23/02/2019 04:01
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-magnifying-glass:before { content: "\f100"; }
.flaticon-magnifying-glass-1:before { content: "\f101"; }
.flaticon-search:before { content: "\f102"; }
.flaticon-next:before { content: "\f103"; }
.flaticon-next-1:before { content: "\f104"; }
.flaticon-slim-right:before { content: "\f105"; }
.flaticon-back:before { content: "\f106"; }
.flaticon-back-1:before { content: "\f107"; }
.flaticon-slim-left:before { content: "\f108"; }
.flaticon-video-player:before { content: "\f109"; }
.flaticon-shopping-bag:before { content: "\f10a"; }
.flaticon-shopping-bag-1:before { content: "\f10b"; }
.flaticon-shopping-bag-2:before { content: "\f10c"; }
.flaticon-shopping-bag-3:before { content: "\f10d"; }
.flaticon-idea:before { content: "\f10e"; }
.flaticon-sheriff:before { content: "\f10f"; }
.flaticon-diamond:before { content: "\f110"; }
.flaticon-gift:before { content: "\f111"; }
.flaticon-suitcase:before { content: "\f112"; }
.flaticon-stats:before { content: "\f113"; }
.flaticon-start:before { content: "\f114"; }
.flaticon-idea-1:before { content: "\f115"; }
.flaticon-chess-piece:before { content: "\f116"; }
.flaticon-green-energy:before { content: "\f117"; }
.flaticon-right-quote:before { content: "\f118"; }
.flaticon-right-quote-1:before { content: "\f119"; }
.flaticon-quote:before { content: "\f11a"; }
.flaticon-happy:before { content: "\f11b"; }
.flaticon-smile:before { content: "\f11c"; }
.flaticon-smiley:before { content: "\f11d"; }
.flaticon-project:before { content: "\f11e"; }
.flaticon-projector:before { content: "\f11f"; }
.flaticon-award:before { content: "\f120"; }
.flaticon-medal:before { content: "\f121"; }