/*--------------------------------------------------------------
	blog page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#6.1	blog-pg-section
--------------------------------------------------------------*/
.blog-pg-section {
	.blog-content {
		@include widther(1200px) {
			padding-right: 50px;
		}

		.post {
			margin-bottom: 100px;

			@include media-query(991px) {
				margin-bottom: 80px;
			}

			@include media-query(767px) {
				margin-bottom: 70px;
			}
		}
	}

	.entry-meta {
		list-style: none;
		overflow: hidden;
		margin: 35px 0;

		@include media-query(767px) {
			margin: 25px 0;
		}

		li {
			float: left;
			font-size: 14px;
			font-size: calc-rem-value(14);

			@include media-query(767px) {
				font-size: 12px;
				font-size: calc-rem-value(12);
			}
		}

		> li + li {
			margin-left: 20px;
			padding-left: 20px;
			position: relative;

			&:before {
				content: "";
				background: $theme-primary-color;
				width: 8px;
				height: 8px;
				border-radius: 8px;
				position: absolute;
				left: -4px;
    			top: 7px;

				@include media-query(767px) {
					top: 5px;
				}
			}
		}

		li a {
			color: $theme-primary-color;
		}
	}

	.post {
		h3 {
			font-size: 30px;
			font-size: calc-rem-value(30);
			line-height: 1.2em;
			font-weight: 600;
			margin: -0.27em 0 0.7em;

			@include media-query(991px) {
				font-size: 25px;
				font-size: calc-rem-value(25);
			}

			@include media-query(767px) {
				font-size: 22px;
				font-size: calc-rem-value(22);
			}
		}

		h3 a {
			color: $heading-color;
		}

		h3 a:hover {
			color: $theme-primary-color;
		}

		p {
			font-size: 18px;
			font-size: calc-rem-value(18);
			margin-bottom: 1.5em;

			@include media-query(991px) {
				font-size: 16px;
				font-size: calc-rem-value(16);
			}
		}

		.more {
			font-family: $heading-font;
			font-size: 16px;
			font-size: calc-rem-value(16);
			font-weight: 600;
			color: $heading-color;
			display: inline-block;
			line-height: 0.5em;
			padding-left: 50px;
			position: relative;

			&:before {
				content: "";
				background: $theme-primary-color;
				width: 32px;
				height: 2px;
				position: absolute;
				left: 0;
				top: 5px;
			}

			@include media-query(991px) {
				font-size: 15px;
				font-size: calc-rem-value(15);
			}

			&:hover {
				color: $theme-primary-color;
			}

			.fi:before {
				font-size: 14px;
				font-size: calc-rem-value(14);
				position: relative;
				top: 1px;
				left: 2px;
			}
		}
	}

	/*** format-standard ***/
	.format-standard,
	.format-quote {
		background-color: #f8f8f8;
		padding: 25px 35px 45px;

		@include media-query(767px) {
			padding: 25px 20px 45px;
		}
	}

	.format-standard-image {
		.video-btn {
			display: none;
		}

	}


	/*** format-gallery ***/
	.format-gallery {
		position: relative;

		.owl-controls {
			width: 100%;
			margin: 0;
			position: absolute;
			left: 0;
			top: 50%;
			@include translatingY();
		}

		.owl-controls .owl-nav [class*=owl-]:hover {
			background: $theme-primary-color;
		}

		.owl-controls .owl-nav [class*=owl-] {
			background: transparentize(#858585, 0.5);
			width: 50px;
			height: 50px;
			line-height: 50px;
			padding: 0;
			margin: 0;
			border-radius: 0;
			@include transition-time(0.3s);
		}

		.owl-controls .owl-nav .owl-prev,
		.owl-controls .owl-nav .owl-next {
			position: absolute;
			top: 50%;
			@include translatingY();
		}

		.owl-controls .owl-nav .owl-prev {
			left: 15px;
		}

		.owl-controls .owl-nav .owl-next {
			right: 15px;
		}
	}

	/*** format-quote ***/
	.format-quote {
		position: relative;

		&:before {
			content: "";
			@include background-style("../../images/blog/quote.png", center center, auto, no-repeat, local);
			width: 57px;
			height: 41px;
			position: absolute;
			right: 45px;
			bottom: 35px;
		}
	}

	/*** format-video ***/
	.format-video {
		
		.entry-media {
			position: relative;

			&:before {
				content: "";
				@include overlay(transparentize($black, 0.5));
				@include transition-time(0.3s);
			}
	
			&:hover:before {
				@include overlay(transparentize($black, 0.3));
			}
	
			button.btn-wrap {
				position: absolute;
				left: 50%;
				top: 50%;
				width: 0px;
				@include center-by-translating();
				background-color: #87d271;
				width: 75px;
				height: 75px;
				border-radius: 75px;
				display: inline-block;
				right: -37px;
				top: 50%;
				animation: spineer 2s infinite;
				border: 0;
				text-align: center;

				:before {
					content: "";
					width: 0px;
					height: 0px;
					border-top: 15px solid transparent;
					border-bottom: 15px solid transparent;
					border-left: 15px solid white;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}


		.fi:before {
			font-size: 80px;
			font-size: calc-rem-value(80);
			color: $white;

			@include media-query(767px) {
				font-size: 60px;
				font-size: calc-rem-value(60);
			}
		}
	}
}

.blog-pg-left-sidebar {
	.blog-content {
		@include widther(1200px) {
			padding-right: 0;
			padding-left: 50px;
		}
	}
}

.blog-pg-fullwidth {
	.blog-content {
		@include widther(1200px) {
			padding: 0;
		}
	}
}

.blog-single-section,
.blog-pg-section {
	.order-lg-2 {
		@include widther(991px) {
			.blog-content {
				padding-right: 0px;
				padding-left: 50px;
			}
		}
	}
}

