/*--------------------------------------------------------------
	blog single page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#7.1	blog-single-section
--------------------------------------------------------------*/
.blog-single-section {
	.blog-content {
		@include widther(1200px) {
			padding-right: 50px;
		}
	}
	
	.entry-meta {
		list-style: none;
		overflow: hidden;
		margin: 35px 0;

		@include media-query(767px) {
			margin: 25px 0;
		}

		li {
			float: left;
			font-size: 14px;
			font-size: calc-rem-value(14);

			@include media-query(767px) {
				font-size: 12px;
				font-size: calc-rem-value(12);
			}
		}

		> li + li {
			margin-left: 20px;
			padding-left: 20px;
			position: relative;

			&:before {
				content: "";
				background: $theme-primary-color;
				width: 8px;
				height: 8px;
				border-radius: 8px;
				position: absolute;
				left: -4px;
    			top: 7px;

				@include media-query(767px) {
					top: 5px;
				}
			}
		}

		li a {
			color: $theme-primary-color;
		}
	}

	.post {
		h2 {
			font-size: 36px;
			font-size: calc-rem-value(36);
			font-weight: 600;
			margin: -0.22em 0 0.7em;
			line-height: 1.3em;

			@include media-query(991px) {
				font-size: 30px;
				font-size: calc-rem-value(30);
			}

			@include media-query(767px) {
				font-size: 25px;
				font-size: calc-rem-value(25);
			}
		}

		p {
			font-size: 18px;
			font-size: calc-rem-value(18);
			margin-bottom: 1.5em;

			@include media-query(991px) {
				font-size: 16px;
				font-size: calc-rem-value(16);
			}
		}

		h3 {
			font-size: 24px;
			font-size: calc-rem-value(24);
			font-weight: 600;
			line-height: 1.3em;
			margin: 1.8em 0 1em;

			@include media-query(991px) {
				font-size: 22px;
				font-size: calc-rem-value(22);
			}

			@include media-query(767px) {
				font-size: 20px;
				font-size: calc-rem-value(20);
			}
		}

		blockquote {
			background-color: $theme-primary-color;
			color: $white;
			line-height: 1.6em;
			padding: 120px 45px 65px;
			margin-top: 60px;
			border: 0;
			position: relative;

			@include media-query(767px) {
				padding: 55px 25px;
			}

			&:before {
				content: "";
				@include background-style("../../images/blog/quote-2.png", center center, auto, no-repeat, local);
				width: 62px;
				height: 44px;
				position: absolute;
				left: 45px;
				top: 40px;

				@include media-query(767px) {
					display: none;
				}
			}

			.quoter {
				font-size: 14px;
				font-size: calc-rem-value(14);
				display: block;
				margin-top: 15px;
			}

		}
	}


	/*** tag-share ***/
	.tag-share {
		border: 1px solid #f2f2f2;
		padding: 25px 25px 35px;
		margin: 55px 0 0;
		color: $heading-color;

		ul {
			list-style: none;
			display: inline-block;
			overflow: hidden;

			li {
				float: left;

				@include media-query(767px) {
					margin: 2px;
				}
			}

			> li + li {
				margin-left: 10px;

				@include media-query(767px) {
					margin: 2px;
				}
			}
		}

		.tag {
			ul {
				list-style: none;
				position: relative;
				top: 15px;
			}

			a {
				font-size: 14px;
				font-size: calc-rem-value(14);
				background-color: #f7f7f7;
				color: #627381;
				padding: 8px 15px;
				display: block;
				border-radius: 50px;

				@include media-query(767px) {
					font-size: 13px;
					font-size: calc-rem-value(13);
					padding: 5px 10px;
				}

				&:hover {
					background-color: $theme-primary-color;
					color: $white;
				}
			}
		}

		.share {
		    margin-top: 40px;

    		ul {
				position: relative;
				top: 15px;
			}

			ul > li + li {
				margin-left: 15px;

				@include media-query(767px) {
					margin-left: 10px;
				}
			}

			ul > li {

				@include media-query(767px) {
					margin: 5px;
				}
			}

			a {
				width: 40px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				border: 1px solid #eae6e6;
				border-radius: 50%;
				display: inline-block;
				font-size: 16px;
				font-size: calc-rem-value(16);
				color: #627381;

				@include media-query(767px) {
					font-size: 13px;
					font-size: calc-rem-value(13);
				}
			}

			a:hover {
				background: $theme-primary-color;
				border-color: $theme-primary-color;
				color: $white !important;
			}

			ul > li:first-child a {
				color: #3c5ba4;
			}

			ul > li:nth-child(2) a {
				color: #47a0d9;
			}

			ul > li:nth-child(3) a {
				color: #0073b1;
			}

			ul > li:nth-child(4) a {
				color: #933f94;
			}
		}

	}


	/*** author-box ***/
	.author-box {
		padding: 35px 40px 25px;
	    margin: 70px 0;
		border: 1px solid #f2f2f2;

	    @include media-query(991px) {
			padding: 35px 40px;
		}

	    @include media-query(767px) {
			padding: 25px;
		}


	    .author-avatar {
	    	float: left;

	    	@include media-query(767px) {
	    		float: none;
	    	}
	    }

	    .author-content {
	    	display: block;
		    overflow: hidden;
		    padding-left: 25px;

		    @include media-query(767px) {
	    		padding: 0;
	    		margin: 15px 0 0 0;
	    	}
	    }

	    .author-content p {
	    	font-size: 17px;
	    	font-size: calc-rem-value(17);
	    	color: #949494;
	    	margin-bottom: 20px;

	    	@include media-query(767px) {
				font-size: 15px;
				font-size: calc-rem-value(15);
			}
	    }

	    .author-name {
	    	font-size: 18px;
	    	font-size: calc-rem-value(18);
	    	font-weight: 600;
	    	display: inline-block;
		    margin-bottom: 10px;
		    color: $heading-color;
	    }

	    .social-link {
	    	display: inline-block;
	    	list-style: none;
	    }

	    .social-link li {
	    	float: left;
	    	margin-right: 15px;
	    }

	    .social-link a {
	    	display: block;
	    	font-size: 15px;
	    	font-size: calc-rem-value(15);
	    	color: $heading-color;
	    }

	    .social-link a:hover {
	    	color: $theme-primary-color;
	    }
	}

	/*** more-posts ***/
	.more-posts {
		overflow: hidden;
	    border: 1px solid #f2f2f2;

		> div {
			width: 50%;
			float: left;
		}

		> div > a {
			display: inline-block;
		}

		.previous-post,
		.next-post {
			font-size: 18px;
			font-size: calc-rem-value(18);
			color: #939ba2;
		    padding: 30px;

		    @include media-query(767px) {
				padding: 20px;
				font-size: 16px;
				font-size: calc-rem-value(16);
			}

		    @include media-query(450px) {
				font-size: 14px;
				font-size: calc-rem-value(14);
			}

			i {
				position: relative;
				top: 2px;

				@include media-query(450px) {
					display: none;
				}
			}
		}

		.next-post {
			text-align: right;
		    border-left: 1px solid #e8e8e8;
		}

		.previous-post > a > span,
		.next-post > a > span {
			display: block;
		}

		.post-control-link {
			color: #939ba2;
			font-weight: 600;
		}

		.next-post i {
			left: 2px;
		}

		a:hover .post-control-link  {
			color: $theme-primary-color;
		}
	}

	/*** comments area ***/
	.comments-area {
		margin-top: 70px;

		.comments {
			box-shadow: 0px 0px 21.17px 7.83px rgba(0, 0, 0, 0.04);
			border-bottom: 0;
		}

		li > div {
			border-bottom: 1px solid #f1f1f1;
			padding: 35px;

			@include media-query(991px) {
				padding: 35px 25px;
			}
		}

		ol {
			list-style-type: none;
			padding-left: 0;

			ul {
				padding-left: 30px;
				list-style-type: none;
			}

			> li:last-child div {
				border-bottom: 0;
			}
		}

		.comments-title {
			font-size: 22px;
			font-size: calc-rem-value(22);
			font-weight: 600;
			margin: 0 0 1.5em;

			@include media-query(991px) {
				font-size: 20px;
				font-size: calc-rem-value(20);
			}
		}

		li > div {
			position: relative;
		}

		.comment-theme {
			position: absolute;
			left: 35px;

			@include media-query(767px) {
				position: static;
			}
		}

		.comment-main-area {
			padding-left: 100px;

			@include media-query(767px) {
				padding-left: 0;
				margin-top: 25px;
			}

			p {
				margin-bottom: 20px;
			}
		}

		.comments-meta {

			h4 {
				font-family: $base-font;
				font-size: 18px;
				font-size: calc-rem-value(18);
				color: #6d6d6d;
				font-weight: bold;
				margin: 0 0 1em;
			}

			h4 span {
				font-family: $base-font;
				font-weight: normal;
				font-style: italic;
				color: #949494;
				text-transform: none;
				display: inline-block;
				padding-left: 5px;
				font-size: 16px;
				font-size: calc-rem-value(16);

				@include media-query(767px) {
					padding-left: 0;
				}
			}
		}

		.comment-reply-link {
			background: lighten($theme-primary-color, 15%);
			font-size: 14px;
			font-size: calc-rem-value(14);
			font-weight: 600;
			color: $white;
			display: inline-block;
			padding: 4px 14px;
			display: inline-block;

			&:hover {
				background-color: $theme-primary-color;
			}
		}
	}


	/*** comment-respond ***/
	.comment-respond {
		margin-top: 70px;

		.comment-reply-title {
			font-size: 22px;
			font-size: calc-rem-value(22);
			margin: 0 0 1.5em;

			@include media-query(991px) {
				font-size: 20px;
				font-size: calc-rem-value(20);
			}
		}

		form input,
		form textarea {
			background-color: #f8f8f8;
			width: 100%;
			height: 50px;
			border: 1px solid #f8f8f8;
			padding: 6px 15px;
			margin-bottom: 15px;
	        outline: 0;
	        box-shadow: none;
	        @include transition-time(0.3s);

			&:focus {
				box-shadow: none;
				border-color: $theme-primary-color;
			}

			@include media-query(991px) {
				height: 40px;
			}
		}

		form textarea {
			height: 220px;
			padding: 15px;

			@include media-query(991px) {
				height: 150px;
			}
		}

		.form-inputs {
			overflow: hidden;
		}

		.form-inputs > input:nth-child(2) {
			width: 49%;
			float: left;

			@include media-query(767px) {
				width: 100%;
				float: none;
			}
		}

		.form-inputs > input:nth-child(3) {
			width: 49%;
			float: right;

			@include media-query(767px) {
				width: 100%;
				float: none;
			}
		}

		.form-submit input {
			max-width: 180px;
			background-color: $theme-primary-color;;
			color: $white;
			font-weight: bold;
			margin-bottom: 0;
			border: 0;
			outline: 0;
			text-transform: uppercase;
		}

		.form-submit input:hover {
			background-color: #313131;
		}
	}
}

.blog-single-left-sidebar-section {
	.blog-content {
		@include widther(1200px) {
			padding-right: 0;
			padding-left: 50px;
		}
	}
}

.blog-single-fullwidth  {
	.blog-content {
		@include widther(1200px) {
			padding: 0;
		}
	}
}
