/*--------------------------------------------------------------
	home style 3
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#3.1	services-section-s3
--------------------------------------------------------------*/
.services-section-s3 {
	padding-bottom: 50px;

	@include media-query(991px) {
		padding-bottom: 40px;
	}

	.services-grids .grid {
		width: 50%;
		float: left;
		position: relative;
		padding: 0 35px 0 95px;
		margin: 0 0 45px;

		@include media-query(991px) {
			padding: 0 35px 0 95px;
		}

		@include media-query(767px) {
			padding: 0 0 0 85px;
			width: 100%;
			float: none;
			margin: 0 0 35px;
		}
	}

	.grid .icon {
		width: 68px;
		height: 68px;
		line-height: 68px;
		text-align: center;
		position: absolute;
		left: 0;
		top: -10px;
		border-radius: 50%;
		box-shadow: 0px 7px 15.75px 5.25px rgba(0, 0, 0, 0.04);
		@include transition-time(0.2s);

		@include media-query(767px) {
			width: 60px;
			height: 60px;
			line-height: 60px;
		}
	}

	.services-grids > .grid:last-child i {
		position: relative;
		left: 5px;

		@include media-query(767px) {
			left: 3px;
		}
	}

	.grid .fi:before {
		font-size: 33px;
		font-size: calc-rem-value(33);
		color: $theme-primary-color;
	    @include transition-time(0.2s);

	    @include media-query(767px) {
			font-size: 28px;
			font-size: calc-rem-value(28);
		}
	}

	.grid h3 {
		font-size: 20px;
		font-size: calc-rem-value(20);
		font-weight: bold;
		margin: 0 0 0.7em;

		@include media-query(991px) {
			font-size: 18px;
			font-size: calc-rem-value(18);
		}
	}

	.grid h3 a {
		color: $heading-color;
	}

	.grid:hover .fi:before {
		color: $white;
	}

	.grid:hover h3 a {
		color: $theme-primary-color;
	}

	.grid:hover .icon {
		background-color: $theme-primary-color;
	}
}


/*--------------------------------------------------------------
#3.2	projects-sections-s2
--------------------------------------------------------------*/
.projects-sections-s2 {
	.project-grids .grid {
		width: 33.33%;
		float: left;
		position: relative;
		overflow: hidden;

		@include media-query(991px) {
			width: 50%;
		}

		@include media-query(600px) {
			width: 100%;
			float: none;
		}

		img {
			max-height: 318px;
			width: 100%;
			object-fit: cover;

			@include media-query(1399px) {
				max-height: 311px;
			}
			@include media-query(1199px) {
				max-height: 260px;
			}
			@include media-query(575px) {
				max-height: 100%;
			}
		}
	}

	.grid .hover-content {
		width: 100%;
		height: 100%;
		padding: 30px;
		position: absolute;
		left: 0;
		top: 100px;
		@include transition-time(0.5s);
		opacity: 0;

		@include media-query(991px) {
			padding: 25px;
		}

		&:before {
			content: "";
			background: transparentize(#1d1d1d, 0.1);
			width: calc(100% + 2px);
			height: 100%;
			position: absolute;
			left: -1px;
			top: 0;
		}
	}

	.grid:hover .hover-content {
		top: 0;
		opacity: 1;
	}

	.hover-content .details {
		position: absolute;
		left: 30px;
		bottom: 30px;
		z-index: 2;

		@include media-query(991px) {
			left: 25px;
			bottom: 25px;
		}
	}

	.details h3 {
		font-size: 20px;
		font-size: calc-rem-value(20);
		color: $white;
		margin: 0 0 0.2em;

		@include media-query(991px) {
			font-size: 20px;
			font-size: calc-rem-value(20);
		}

		@include media-query(767px) {
			font-size: 18px;
			font-size: calc-rem-value(18);
			font-weight: normal;
		}
	}

	.details .cat {
		color: $theme-primary-color;
	}

	.top-link {
		position: relative;
		z-index: 2;
	}

	.top-link .fi:before {
		@include transition-time(0.2s);
	}

	.top-link .fi:before {
		font-size: 25px;
		font-size: calc-rem-value(25);
		color: $white;
	}

	.top-link a:hover .fi:before {
		color: $theme-primary-color;
	}

	.all-projects {
		font-family: $heading-font;
		text-align: center;
		margin-top: 40px;

		a {
			color: $heading-color;
		}

		a:hover {
			color: $theme-primary-color;
		}

		i {
			position: relative;
			top: 1px;
			left: 4px;
		}

		i:before {
			font-size: 14px;
			font-size: calc-rem-value(14);
		}
	}

}