/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/
html {
    font-size: $base-font-size + px;
}

:root {
    scroll-behavior: unset;
}

body {
    font-family: $base-font;
    background-color: $body-bg-color;
    font-size: 16px;
    font-size: calc-rem-value(16);
    color: $text-color;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;

    @include media-query( 767px ) {
        font-size: 15px;
        font-size: calc-rem-value(15);
    }
}

p {
    color: $text-color;
    line-height: 1.6em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $heading-font;
    font-weight: bold;
    color: $heading-color;
}

ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
}

a {
    text-decoration: none;
    @include transition-time(0.2s);
}

a:hover {
    text-decoration: none;
}

img {
    max-width: 100%;
}

.hidden {
    display: none;
}

/*--------------------------------------------------------------
#0.2    Global Elements
--------------------------------------------------------------*/
.App {
    position: relative;
    overflow: hidden;
}

.box-style {
    padding: 0 40px;
    
    @include media-query(1199px) {
        padding: 0 30px;
    }
    
    @include media-query(991px) {
        padding: 0;
    }
}

.wow {
    visibility: hidden;
}

.fi:before {
    margin: 0;
}

.section-padding {
    padding: 110px 0;

    @include media-query(991px) {
        padding: 90px 0;
    }

    @include media-query(767px) {
        padding: 80px 0;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}


/*** contact form error handling ***/
.contact-validation-active {
    .error-handling-messages {
        width: 100% !important;
        margin-top: 15px !important;
    }

    label.error {
        color: red;
        font-size: calc-rem-value(14);
        font-weight: normal;
        margin: 5px 0 0 0;
        text-align: left;
        display: block;
    }

    #loader {
        display: none;
        margin-top: 10px;

        i {
            font-size: 30px;
            font-size: calc-rem-value(30);
            color: $theme-primary-color;
            display: inline-block;
            -webkit-animation: rotating linear 2s infinite;
            animation: rotating linear 2s infinite;
        }
    }

    #success,
    #error {
        width: 100%;
        color: $white;
        padding: 5px 10px;
        font-size: 16px;
        text-align: center;
        display: none;

        @include media-query(767px)  {
            font-size: 15px;
        }
    }

    #success {
        background-color: lighten(green, 5%);
        border-left: 5px solid green;
        margin-bottom: 5px;
    }

    #error {
        background-color: lighten(red, 5%);
        border-left: 5px solid red;
    }
}

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating {
  -webkit-animation: rotating 5s alternate infinite;
  animation: rotating 5s alternate infinite;
}

/*** back to top **/
.back-to-top {
    background-color: #1d1d1d;
    width: 45px;
    height: 45px;
    line-height: 45px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    text-align: center;
    position: absolute;
    z-index: 999;
    left: calc(50% - 22.5px);
    top: -22.5px;
    @include transition-time(0.2s);

    @include media-query(991px) {
        width: 40px;
        height: 40px;
        line-height: 40px;
    }

    &:hover {
        background-color: $theme-primary-color;
    }
}

.back-to-top i {
    font-size: 18px;
    font-size: calc-rem-value(18);
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    @include center-by-translating();

     @include media-query(991px) {
        font-size: 15px;
        font-size: calc-rem-value(15);
    }
}


/** for popup image ***/
.mfp-wrap {
    background-color: transparentize($black, 0.1);
    z-index: 99999;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}

/*** for fancybox video ***/
.fancybox-overlay {
  background: rgba(0, 0, 0, 0.9);
  z-index: 9999 !important;
}

.fancybox-wrap {
  z-index: 99999 !important;
}

